import useSoundLibrary from "use-sound";
import { soundSetting } from "service";
import {
  GameOver,
  SoundClickButton,
  SoundDuplicate,
  SoundGreenLine,
  SoundOrangeClick,
  SoundOrangeTile,
  SoundOrangeTileDelay,
  SoundRolling,
  SoundSuspense777,
  Strike7,
  CountUpWin,
  JackpotSound,
  SwooshSound,
  TripleSevenLastHit,
  SideBetWinner,
} from "assets/audio";
import { createEmptySoundStub } from "service/sound";

export const useSound = () => {
  const [playSuspense777, { stop: stopSuspense777 }] = useSoundLibrary(SoundSuspense777, { ...soundSetting, loop: true });
  const [playSoundOrangeTile] = useSoundLibrary(SoundOrangeTile, soundSetting);
  const [playSoundClickButton] = useSoundLibrary(SoundClickButton, soundSetting);
  const [playSoundOrangeTileDelay] = useSoundLibrary(
    SoundOrangeTileDelay,
    soundSetting
  );
  const [playSoundOrangeClick] = useSoundLibrary(SoundOrangeClick, soundSetting);
  const [playSoundRolling, { stop: stopSoundRolling }] = useSoundLibrary(
    SoundRolling,
    {
      ...soundSetting,
      loop: true
    }
  );
  const [playSoundDuplicate] = useSoundLibrary(SoundDuplicate, soundSetting);
  const [playSideBetWinner] = useSoundLibrary(SideBetWinner, soundSetting);
  const [playSoundGreenLine] = useSoundLibrary(SoundGreenLine, soundSetting);
  const [playSoundStrike] = useSoundLibrary(Strike7, soundSetting);
  const [playCountUpWin] = useSoundLibrary(CountUpWin, soundSetting);
  const [playGameOver] = useSoundLibrary(GameOver, soundSetting);
  const [playJackpotSound] = useSoundLibrary(JackpotSound, soundSetting);
  const [playSwooshSound] = useSoundLibrary(SwooshSound);
  const [playTripleSevenLastStrike] = useSoundLibrary(TripleSevenLastHit);
  const playOut = createEmptySoundStub();

  return {
    playSuspense777,
    stopSuspense777,
    playSoundOrangeTile,
    playSoundClickButton,
    playSoundOrangeTileDelay,
    playSoundOrangeClick,
    playSoundRolling,
    stopSoundRolling,
    playSoundDuplicate,
    playSideBetWinner,
    playSoundGreenLine,
    playSoundStrike,
    playCountUpWin,
    playGameOver,
    playJackpotSound,
    playSwooshSound,
    playTripleSevenLastStrike,
    playOut
  };
}

export default useSound